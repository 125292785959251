import { AUTOWASH_TENANT_KEY, TenantContext } from 'src/contexts/TenantContext';
import { useContext } from "react";
import { useIsAdmin } from "./useIsAdmin";

export const useIsSuperAdmin = () => {
	const isAdmin = useIsAdmin();
    const { ampTenantKey } = useContext(TenantContext);
    const hasAutowashAmpTenant = ampTenantKey === AUTOWASH_TENANT_KEY;
	return isAdmin && hasAutowashAmpTenant;
};
