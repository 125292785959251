import { handlePassThroughError, EErrorIdInternal, IError, XMaybe, getError } from "amp";
import {
	createContext,
	ReactNode,
	useCallback,
	useMemo,
	useState,
} from "react";
import ErrorModal from "src/components/ErrorModal";
import { IDisplayError } from "src/interfaces/display-error";
import { handleInvalidPromoCodeError } from "src/utils/error/invalid-promo-code-.error";

export const DEFAULT_ERROR = {
	name: "Something went wrong!",
	id: EErrorIdInternal.Unknown,
	message: "There was an unknown error. Please contact support if this issue persists."
};
interface IErrorContext {
	errors: IDisplayError[];
	addError: (error: unknown) => void;
	dismissError: (errorId: string) => void;
}

const defaultErrorContext: IErrorContext = {
	errors: [],
	addError: () => null,
	dismissError: () => null,
};

export const ErrorContext = createContext(defaultErrorContext);

const ErrorContextProvider = ({ children }: { children: ReactNode }) => {
	const [errors, setErrors] = useState<IDisplayError[]>([]);

	const addError = useCallback((error: XMaybe<IError<unknown>>) => {
		const displayError = _getDisplayError(error);
		setErrors((stateErrors) => [
			...stateErrors,
			{ ...JSON.parse(JSON.stringify(displayError)) },
		]);
	}, []);

	const dismissError = useCallback((errorId: string) => {
		setErrors((stateErrors) =>
			stateErrors.filter((stateError) => stateError.id !== errorId),
		);
	}, []);

	const context: IErrorContext = useMemo(
		() => ({
			errors,
			addError,
			dismissError,
		}),
		[errors, addError, dismissError],
	);
	return (
		<ErrorContext.Provider value={context}>
			{children}
			{errors.map((contextError: IDisplayError) => (
				<div key={contextError.id}>
				<ErrorModal
					key={contextError.id}
					error={contextError}
					dismissError={dismissError}
				/>
				</div>
			))}
		</ErrorContext.Provider>
	);
};

export default ErrorContextProvider;

const _getError = (error: unknown): IError<unknown> => {
	if ((error as any).id) {
		return error as IError<unknown>;
	}
	return getError((error as any)?.response?.data?.error);
};

const _getDisplayError = (error: unknown): IDisplayError => {
	const apiError = _getError(error);
	switch (apiError?.id) {
		case "INVALID_PROMO_CODE":
			return handleInvalidPromoCodeError(apiError);
		case undefined:
			return DEFAULT_ERROR;
		default: {
			return handlePassThroughError(apiError) ?? DEFAULT_ERROR;
		}
	}
};